<template>
  <b-modal
    body-class="select-campaign-modal"
    @hidden="CloseModalNetwork"
    @shown="onModalShown"
    centered
    hide-footer
    hide-header
    v-model="show"
    :size="size"
  >
    <div :class="`header-select-campaign ${padding} ${bg_title}`">
      <feather-icon
        icon="XIcon"
        size="24"
        :class="closeIcon"
        @click="show = !show"
      />
      <slot name="title"></slot>
    </div>
    <div :class="`${bodyClass} ${bg_body}`">
      <div v-if="show">
        <b-form @submit.prevent="handleSubmit">
          <b-form-group>
            <label for="account-username">{{ $t("creator.namecard") }}</label>
            <b-form-input
              type="text"
              v-model="cardholderName"
              :placeholder="$t('creator.namecard')"
              :name="$t('creator.namecard')"
              :state="cardholderName.length > 0 ? true : false"
            />
            <small class="text-danger"></small>
          </b-form-group>
          <div id="card-element" class="card-element"></div>
          <button
            type="submit"
            class="stripe-button"
            :disabled="!isFormValid || cardholderName.length == 0"
          >
            <span v-if="!loading">{{ $t("creator.updatedPayment") }}</span>
            <span v-if="loading">
              <b-icon icon="circle-fill" animation="throb"></b-icon>
              {{ $t("creator.processing") }}
            </span>
          </button>
        </b-form>
      </div>
      <slot name="body"></slot>
    </div>

    <div :class="`${class_footer} ${bodyClass}`" v-if="!no_footer">
      <slot name="footer"></slot>
    </div>
  </b-modal>
</template>
  
<script>
import { BModal, BForm, BFormGroup, BFormInput, BIcon } from "bootstrap-vue";
import { loadStripe } from "@stripe/stripe-js";
import proposals_services from "@/services/proposals";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: "ModalStripe",
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BIcon,
  },
  data() {
    return {
      show: this.show_modal,
      stripe: null,
      elements: null,
      loading: false,
      isFormValid: false,
      cardholderName: "",
    };
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
  },
  methods: {
    validateCardholderName() {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(this.cardholderName)) {
        this.nameError = "El nombre solo puede contener letras y espacios.";
        this.cardholderName = this.cardholderName.replace(/[^a-zA-Z\s]/g, "");
      } else {
        this.nameError = "";
      }
    },
    onModalShown() {
      if (this.stripe) {
        this.elements = this.stripe.elements();
        const cardElement = this.elements.create("card");
        cardElement.mount("#card-element");

        cardElement.addEventListener("change", this.handleChange);
      }
    },
    handleChange(event) {
      this.isFormValid = event.complete;
    },
    async handleSubmit() {
      this.loading = true;
      const { token, error } = await this.stripe.createToken(
        this.elements.getElement("card"),
        {
          name: this.cardholderName,
        }
      );

      if (error) {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.titleUnpublished'),
              text: this.$t('creator.textCardPymayment'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
          })
        })
        this.CloseModalNetwork()
        this.loading = false;
        return;
      }
      const data = {
        token_card: token.id,
      };
      const response = await proposals_services.updatePaymentMethod(data);
      if (response.status === 'active') {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.titleUnpublished'),
              text: this.$t('creator.textCardPymayment'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
          })
        })
      } else {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.errorTitleCardPymaymen'),
              text: this.$t('creator.errorTextCardPymayment'),
              icon: "AlertTriangleIcon",
              variant: 'danger',
            }
          })
        })
      }
      this.loading = false;
      this.CloseModalNetwork()
    },
    CloseModalNetwork() {
      this.$emit("hidden");
    },
  },
  props: {
    no_footer: {
      type: Boolean,
      default: false,
    },
    class_footer: {
      type: String,
      default: "class-footer-bm",
    },
    bg_body: {
      type: String,
      default: "class-bg-body-bm",
    },
    bg_title: {
      type: String,
      default: "class-bg-header-bm",
    },
    show_modal: {
      type: Boolean,
      required: true,
    },
    padding: {
      type: String,
      default: "p-1",
    },
    size: {
      type: String,
      default: "md",
    },
    closeIcon: {
      type: String,
      default: "close-select-campaign-modal",
    },
    bodyClass: {
      type: String,
      default: "p-1",
    },
  },
};
</script>
  
<style scoped>
.class-footer-bm {
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.class-bg-body-bm {
  background-color: white;
}
.class-bg-header-bm {
  background-color: white;
}
.select-campaign-modal {
  padding: 0px !important;
}
.close-select-campaign-modal {
  display: block;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
}
.close-select-campaign-modal:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.header-select-campaign {
  padding: 1em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
}
.header-select-campaign span {
  font-size: 18px;
}
.card-element {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
}
.stripe-button {
  background-color: #0096fe;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.stripe-button:hover {
  background-color: rgb(77, 182, 249);
}

.stripe-button:focus {
  background-color: #0096fe;
  outline: none
}
.stripe-button:disabled {
  background-color: #0094fe86;
}
</style>